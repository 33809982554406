import React from 'react';
import PropTypes from 'prop-types';
import CompanyCard from './CompanyCard';

const amountOfCardsToShow = 9;
class Cards extends React.Component {
  constructor(props) {
    super(props);

    const allItems = this.shuffleCompanies(this.props.allItems);

    this.state = {
      allItems: allItems,
      items: this.showInitialCards(allItems)
    };
  }

  showInitialCards = (allItems) => {

    const companies = allItems;
    let initialCards = [];
    for (var i = 0; i < amountOfCardsToShow; i++) {
      initialCards.push(companies[i]);
    }
    return initialCards;
  };

  shuffleCompanies = (companies) => {
    const shuffled = [...companies];
    shuffled.sort(() => Math.random() - 0.5);
    return shuffled;
  };

  showAllCards = () => {
    this.setState({items: this.state.allItems});
  };

  render () {
    return (
      <div className="cusp-story-cards cusp-bg-gray-icons">
        <div className="slds-container--center slds-p-bottom--large ext-tbz-cards">
            <ul className="slds-grid slds-grid--align-center slds-container--fluid1 slds-wrap slds-grid--pull-padded">
                {
                  this.state.items.map((edge, index) => {
                    const { node } = edge;
                    return (
                    <CompanyCard
                        key={`customer-story_${index + 1}`}
                        item={node}
                        path="classes"
                    />
                    );
                  }
                  )
              }
            </ul>
        </div>
            {
              Boolean(this.state.items.length !== this.state.allItems.length)
              &&
              (<div className="slds-text-align--center" id="moreCardsbtn">
            <a className="slds-button cusp-btn--blue slds-m-top--x-large" onClick={this.showAllCards}>EXPLORE MORE</a>
        </div>)}
      </div>
    );
  }
};

Cards.propTypes = {
      allItems: PropTypes.arrayOf(PropTypes.shape()).isRequired
  };

export default Cards;
