import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const Banner = () => {
    return (
    <div className="cusp-hero-home">
        <div className="">
            <div className="cusp-hero_title">
                CUSTOMER SPOTLIGHTS
            </div>
            <hr className="cusp-hero_hr" />
            <div className="cusp-hero_description">
                Explore success stories from companies using Trailhead to drive learning across their organization.
            </div>
        </div>
    </div>
    );
};

export default Banner;