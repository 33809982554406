import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

const CompanyCard = ({ item }) => {
    const {
        cardBackgroundImage,
        slug,
        companyName,
        companyLogo,
        trailheadUsageText,
        quoteJobTitle,
        cardQuote,
        quoteName
    } = item;

return (
    <li className="cusp-card">
        <div id="normalCard" >
            <div className="cusp-card-header-background card-background-3"></div>
            <div className="cusp-card-logo-container slds-align--absolute-center">
            <img className="cusp-card-logo-image logo-salesforce" src={companyLogo}>
            </img></div>
            <div className="cusp-card--body">
                <div className="slds-align--absolute-center slds-text-heading--large slds-m-top--medium C(b) Fz(18px) cusp-card-body-heading"> <b>{companyName}</b> </div>
                <div className="slds-p-top--x-small slds-text-align--center slds-p-bottom--medium slds-p-left--medium1 slds-p-right--medium1 slds-align--absolute-center slds-text-align-center C(G) Fz(14px) Lh(22px) Maw(270px) cusp-card-body-text">{trailheadUsageText}
                </div>
                </div>
        </div>
                <a id="hoverCard" className="cusp-card-overlay card-overlay" href={`/customer-spotlights/${slug}`}>
                    <div className="cusp-card-quote-text">{`"`+ cardQuote +`"`}<br></br>
                        <div className="cusp-card-sub-quote slds-p-top--large">
                            <b>{quoteName}</b><br></br>{quoteJobTitle},<br></br>{companyName}
                        </div>
                    </div>
                </a>
    </li>
    );
};

CompanyCard.propTypes = {
item: PropTypes.shape().isRequired
};

export default CompanyCard;
