import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Layout from '../layouts/Layout';
import PropTypes from 'prop-types';
import Banner from '../components/Banner';
import Cards from '../components/Cards';

const HomeTemplate = data => {
  const {pageContext: {items}} = data;
  return (
    <Layout>
      <Banner></Banner>
      <Cards allItems={items}></Cards>
    </Layout>
  );
};

export default HomeTemplate;
